const { format, differenceInHours } = require("date-fns")

function formatLastSavedDate(isoDateString, title) {
  if (!isoDateString) return ""
  const date = new Date(isoDateString)
  const now = new Date()
  // Calculate merchant's timezone offset in minutes
  const merchantTimezoneOffset = now.getTimezoneOffset()

  // Calculate the time difference in hours and minutes
  const hoursDifference = differenceInHours(now, date)
  const lastSavedTitle = title ? title : "Last saved"
  // Format the date or time based on the time difference
  if (hoursDifference < 24 && date.getDate() === now.getDate()) {
    // Today
    return `${lastSavedTitle}: ${format(date, "h:mm aaa")} GMT${
      merchantTimezoneOffset >= 0 ? "" : "+"
    }${-merchantTimezoneOffset / 60}`
  } else if (hoursDifference < 48) {
    // Yesterday
    return `${lastSavedTitle}: Yesterday at ${format(date, "h:mm aaa")} GMT${
      merchantTimezoneOffset >= 0 ? "" : "+"
    }${-merchantTimezoneOffset / 60}`
  } else {
    // Longer than 48 hours ago
    return `${lastSavedTitle}: ${format(date, "MMM d 'at' h:mm aaa")} GMT${
      merchantTimezoneOffset >= 0 ? "" : "+"
    }${-merchantTimezoneOffset / 60}`
  }
}
export default formatLastSavedDate
