import React, {
    Fragment,
    useState,
    useEffect,
    useCallback,
  } from 'react'
import {
    Button,
    Card,
    TextField,
    Select,
    Stack,
    Tag,
    Icon,
    Modal,
    TextContainer,
    Spinner,
    OptionList,
    Checkbox,
    ChoiceList,
    InlineError,
    Heading,
} from "@shopify/polaris"
import {
    DeleteMajor,
    SearchMajor,
    SettingsMajor
  } from '@shopify/polaris-icons';
import styled from 'styled-components'
import update from 'immutability-helper'
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
import MulProductPickerModal from '../../../../../components/mulProductPickerModal'
import ConditionSelector from './conditionSelector';
import VariantsPickerModal from '../../../../../components/variantsPickerModal'
import formatLastSavedDate from '../../../../../helpers/formatLastSavedDate'
import CustomerTagModal from '../../checkout/customerTagModal';
import ProductPickerModal from '../../../../../components/productPickerModal';

const DeleteWrapper = styled.div`
    cursor: pointer;
`
const DiscountEditWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 518;
`
const DiscountCodeWrapper = styled.p`
    font-size: 16px;
    line-height: 1.5;
`
const ProductPicker = styled.div`
    .mockInputContainer-error {
        background-color: var(--p-surface-critical-subdued);
        border-color: var(--p-border-critical);
    }
`
const GET_COLLECTION_PRODUCTS = gql`
        query Nodes($ids: [ID!]!){
            nodes(ids: $ids) {
            ...on Collection {
                id,
                products(first:100){
                    edges {
                        node {
                        id
                        }
                    }
                },
            }
            }
        }
`
const GET_TAGS = gql`
   {
    shop{
      productTags(first: 250){
        edges{
          node
        }
      }
    }
  }
`
const GET_MARKETS = gql`
   {
      markets(first:100) {
         edges {
            node {
               id
               name
               enabled
            }
         }
      }
   }
`
const GET_CUSTOMER_TAGS = gql`
   {
    shop{
      customerTags(first: 250){
        edges{
          node
        }
      }
    }
  }
`
const GET_PRODUCTS_BY_TAG = gql`
    query getProductsbyTag($tag: String!){
        products(first:100, query:$tag) {
        pageInfo {
            hasNextPage
        }
        edges {
            cursor
            node {
            id
            tags
            }
        }
        }
    }
`
function Conditions(props) {
    const { host, token, conditions, swap, swapQuantity, autoAdd, factsOptions, handleSelectedFactChange, operatorsOptions, productOperatorOptions, customerOperatorOptions, discountOperatorOptions, handleOperatorChange, handleChange, addOrCondition, addAndCondition, deleteOrCondition, deleteAndCondition, productPickerShow, setProductPickerShow, setCollectionProducts, setTagProducts, setMarket, setCustomerTag, currencySymbol, handleSwapChange, handleSwapQuantityChange, handleAutoAddChange, shop, type, isExcludeProduct, isExcludeOffer, handleExcludeProductChange, handleExcludeOfferChange, checkoutType, discountCode = {}, handleDiscountCodeChange, handleDiscountCodeStatusChange, offerType = 'manual', existingOffer} = props
    const [mulProductPickerVisible, setMulProductPickerVisible] = useState(false)
    const [collectionPickerVisible, setCollectionPickerVisible] = useState(false)
    const [variantsPickerVisible, setVariantsPickerVisible] = useState(false)
    const [tagModalVisible, setTagModalVisible] = useState(false)
    const [discountEditModalVisible, setDiscountEditModalVisible] = useState(false)
    const [productIndex, setProductIndex] = useState([])
    const [productIDs, setProductIDs] = useState([])
    const [qtyProductIDs, setQtyProductIDs] = useState([])
    const [cartValue, setCartValue] = useState(false)
    const [coupons, setCoupons] = useState(null)
    const [couponDuplicated, setCouponDuplicated] = useState([])
    const [swapQuantityOption, setSwapQuantityOption] = useState(swapQuantity ? ['fixed']:['same'])
    const andConditionsArray = conditions.all
    const [getCollectionProducts, { loading, error, data }] = useLazyQuery(GET_COLLECTION_PRODUCTS);
    const [getTags, tags] = useLazyQuery(GET_TAGS);
    const [getCustomerTags, customerTags] = useLazyQuery(GET_CUSTOMER_TAGS);
    const [getProductsbyTag, tagProducts] = useLazyQuery(GET_PRODUCTS_BY_TAG);
    const [getMarkets, markets] = useLazyQuery(GET_MARKETS);
    const handleTagModalChange = () => {
        if( !tagModalVisible )
        {
            getTags()
        }
        setTagModalVisible(!tagModalVisible)
    }
    const handleDiscountEditModalChange = () => {
        setDiscountEditModalVisible(!discountEditModalVisible)
    }
    const handleAddCodes = (value, index, orIndex) => {
        if(coupons){
            let couponArray = coupons.includes('\n') && !coupons.includes(',') ? coupons.split('\n').map( c => c.trim()) : coupons.split(',').map( c => c.trim())
            couponArray = couponArray.filter((value, index, array) => 
                array.indexOf(value) === index
              )
            if(!value){
                handleChange(index, orIndex, couponArray, "discountCode"); 
            }else{
                const newValue = Array.isArray(value) ? value : value.includes('\n') && !value.includes(',') ? value.split('\n').map( c => c.trim()) : value.split(',').map( c => c.trim())
                const intersection = couponArray.filter(x => newValue.includes(x));
                const difference = couponArray.filter(x => !newValue.includes(x));
                const newCodes = newValue.concat(difference)
                if(Array.isArray(intersection)){
                    setCouponDuplicated(intersection)
                }
                handleChange(index, orIndex, newCodes, "discountCode");
            }
            setCoupons(null);
        }
    }
    const handleCustomerTagModalChange = () => {
        if( !tagModalVisible )
        {
            getCustomerTags()
        }
        setTagModalVisible(!tagModalVisible)
    }
    const saveTagProducts = (conditionIndex) => {
        setProductIndex(conditionIndex)
        if(filledTag){
            getProductsbyTag({
                variables: {
                    tag: `tag:${filledTag}`
                }
            })
        }else{
            getProductsbyTag({variables:  {tag: "tag:"+selectedTag[0]}})
        }
    }
    const [selectedTag, setSelectedTag] = useState([])
    const [filledTag, setFilledTag] = useState(null)
    const [selectedCustomerTag, setSelectedCustomerTag] = useState([])
    const [searchTag, setSearchTag] = useState(null)
    const [searchCustomerTag, setSearchCustomerTag] = useState(null)
    const handleSearchTag = useCallback((newValue) => setSearchTag(newValue), []);
    const handleSearchCustomerTag = useCallback((newValue) => setSearchCustomerTag(newValue), []);
    const handleTagValueChange = (value) => {
        if(filledTag){
            setFilledTag(null)
        }
        setSelectedTag(value)
       
    }
    useEffect(() => {
        if(productIndex.length === 2 && !loading && error === undefined && data ){
            setCollectionProducts(productIndex[0], productIndex[1], data, cartValue)
        }
      }, [data]);
    useEffect(() => {
        if(productIndex.length === 2 && productIDs !== []){
            handleChange(productIndex[0], productIndex[1],productIDs,"product")
        }
      }, [productIDs]);

    useEffect(() => {
        if(productIndex.length === 2 && qtyProductIDs !== []){
            handleChange(productIndex[0], productIndex[1],qtyProductIDs,"qtyProduct")
        }
      }, [qtyProductIDs]);
      
    useEffect(() => {
        if(tagProducts.data && !tagProducts.loading && tagProducts.called){
            //handleChange(productIndex[0], productIndex[1],productIDs,"product")
            if(filledTag){
                setTagProducts( productIndex[0], productIndex[1], filledTag, tagProducts.data )
            }else{
                setTagProducts( productIndex[0], productIndex[1], selectedTag[0], tagProducts.data )
            }
            setTagModalVisible(false)
        }
      }, [tagProducts]);
    
    
    
    let searchedTags = []
    if(tags.data){
        searchedTags = tags.data.shop.productTags.edges;
        if(searchTag){
            searchedTags = tags.data.shop.productTags.edges.filter(tag =>  tag.node.toLowerCase().match(searchTag.toLowerCase()))
        }
    }
    const swapRuleArray = ['hasAny','inCollection','hasTag']
    const customerLanguageOptions = [
        {value: 'ar',label: 'Arabic'},
        {value: 'bn',label: 'Bangla'},
        {value: 'cs',label: 'Czech'},
        {value: 'da',label: 'Danish'},
        {value: 'de',label: 'German'},
        {value: 'el',label: 'Greek'},
        {value: 'en',label: 'English'},
        {value: 'es',label: 'Spanish'},
        {value: 'fi',label: 'Finnish'},
        {value: 'fr',label: 'French'},
        {value: 'he',label: 'Hebrew'},
        {value: 'hi',label: 'Hindi'},
        {value: 'hu',label: 'Hungarian'},
        {value: 'id',label: 'Indonesian'},
        {value: 'it',label: 'Italian'},
        {value: 'ja',label: 'Japanese'},
        {value: 'ko',label: 'Korean'},
        {value: 'nl',label: 'Dutch'},
        {value: 'no',label: 'Norwegian'},
        {value: 'pl',label: 'Polish'},
        {value: 'pt',label: 'Portuguese'},
        {value: 'ro',label: 'Romanian'},
        {value: 'ru',label: 'Russian'},
        {value: 'sk',label: 'Slovak'},
        {value: 'sv',label: 'Swedish'},
        {value: 'ta',label: 'Tamil'},
        {value: 'th',label: 'Thai'},
        {value: 'tr',label: 'Turkish'},
        {value: 'zh',label: 'Chinese'}
    ]

    const renderConditionSelector = (index, orIndex, conditionOperator, conditionValue) => {
        switch (conditionOperator) {
            case 'hasCustomerTag':
            case 'hasNoCustomerTag':
                return (<CustomerTagModal setCustomerTag={setCustomerTag} conditionValue={conditionValue?.tag} index={index} orIndex={orIndex}/>)
            case 'isInMarket':
            case 'isNotInMarket':
                let customerMarkets = []
                if (markets.data) {
                    customerMarkets = markets.data.markets.edges;
                    if (customerMarkets) {
                        customerMarkets = markets.data.markets.edges
                          .filter((market) => market.node.enabled)
                          .map(({node}) => ({value: node.id, label: node.name}));
                    }
                }

                return (
                  <ConditionSelector
                    type={'market'}
                    index={index}
                    orIndex={orIndex}
                    conditionValue={conditionValue}
                    setResource={setMarket}
                    getResources={getMarkets}
                    resources={customerMarkets}
                    title={'market'}
                    activatorTitle={'Market'}
                    loading={markets?.loading}
                  />
                )
            case 'hasCustomerLanguage':
                return (
                  <Select
                    options={customerLanguageOptions}
                    onChange={(value) => handleChange(index, orIndex, value,"customerLanguage")}
                    // value={conditionValue || ''}
                    value={customerLanguageOptions
                      .find(({value}) => conditionValue &&
                        value.slice(0,2) === conditionValue.slice(0,2))?.value || ''}
                    label={'Language selector'}
                    labelHidden
                  />
                )
        }
    };
    return (
        <div>
        {andConditionsArray.map((andCondition,index) => {
         return <div key={index}>
                <Card sectioned>
                    { andCondition.any.map((orCondition, orIndex) => {
                   return <div key={orIndex}>
                       { "cartProducts" !== orCondition.fact ?
                        "customer" === orCondition.fact ?
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                            <Stack wrap={false} alignment="center">
                                <Stack.Item>
                                <Select
                                options={factsOptions}
                                onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                value={orCondition.fact}
                                />
                                </Stack.Item>
                                <Stack.Item>
                                <label>is</label>
                                </Stack.Item>
                                <Stack.Item>
                                <Select
                                options={customerOperatorOptions}
                                onChange={(value) => handleOperatorChange(index, orIndex, value)}
                                value={orCondition.operator}
                                />
                                </Stack.Item>

                                <Stack.Item fill>
                                    {
                                        renderConditionSelector(index, orIndex, orCondition.operator, orCondition.value)
                                    }
                               </Stack.Item>
                            </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex, andCondition.any.length)}>
                                    <Icon
                                        source={DeleteMajor}
                                        color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        :
                        "cartProductQTY" === orCondition.fact ?
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                            <Stack wrap={false} alignment="center">
                                <Stack.Item>
                                <Select
                                options={factsOptions}
                                onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                value={orCondition.fact}
                                />
                                </Stack.Item>
                                <div>
                                <label>of</label>
                                </div>
                                <Stack.Item>
                                <ProductPicker>
                                                    <div className={!orCondition.product ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'} style={{marginBottom:'-5px'}}>
                                                    <div>
                                                        <div 
                                                            className='mockInput'
                                                            onClick={() => {setProductPickerShow('mulProducts-'+index+'-'+orIndex);setMulProductPickerVisible(true)}}
                                                            >
                                                            { 
                                                            !orCondition.product ?
                                                                <span>Select products</span> :
                                                                <div>
                                                                <span>... </span>
                                                                <span> | Edit </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        {'mulProducts-'+index+'-'+orIndex === productPickerShow ?
                                                        <MulProductPickerModal
                                                            shop={shop}
                                                            host={host}
                                                            token={token}
                                                            resourceType="Product"
                                                            open={mulProductPickerVisible}
                                                            initialSelectionIds={orCondition.product?orCondition.product:[]}
                                                            showVariants={false}
                                                            onSelect={(selectPayload) => {
                                                                setProductIndex([index, orIndex])
                                                                if (selectPayload.selection.length === 0) {
                                                                if (orCondition.value) {
                                                                    // Clear selection if one exists
                                                                    //handleChange(index, orIndex, null, "product")
                                                                    setQtyProductIDs(null)
                                                                }
                                                                return
                                                                }
                                                                setQtyProductIDs(selectPayload.selection.map(item => {return {'id':item.id}}))
                                                                //handleChange(index, orIndex, selectPayload.selection.map(item => {return {'id':item.id}}), "product")
                                                            }}
                                                            onClose={() => {setMulProductPickerVisible(false);setProductPickerShow('product')}}
                                                            key={index+"-"+orIndex}
                                                            />
                                                            :
                                                            false
                                                        }
                                                    </div>
                                                    </div>
                                        </ProductPicker>
                                </Stack.Item>
                                <div>
                                <label>is</label>
                                </div>
                                <Stack.Item fill>
                                    <TextField
                                        value={orCondition.value && orCondition.value.qty !== null ? orCondition.value.qty : ""}
                                        onChange={(value) => handleChange(index, orIndex, value, "qty")}
                                        autoComplete="off"
                                        placeholder="0"
                                        type="number"
                                        error={orCondition.value === null ? true:false}
                                    />
                               </Stack.Item>
                            </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex, andCondition.any.length)}>
                                    <Icon
                                        source={DeleteMajor}
                                        color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        :
                        "cartValue" === orCondition.fact ?
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                            <Stack wrap={false} alignment="center">
                                <Stack.Item>
                                <Select
                                options={factsOptions}
                                onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                value={orCondition.fact}
                                />
                                </Stack.Item>
                                <div style={{margin:'0 -8px'}}>
                                <label>of</label>
                                </div>
                                <Stack.Item>
                                <ProductPicker>
                                    <div className={!orCondition.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'} style={{marginBottom:'-5px'}}>
                                                    <div>
                                                        <div 
                                                            className='mockInput'
                                                            onClick={() => {setProductPickerShow('collection'+index+'-'+orIndex);setCollectionPickerVisible(true)}}
                                                            >
                                                            { 
                                                            !orCondition.value || !orCondition.value.productIDs ?
                                                                <span>Select collections</span> :
                                                                <div>
                                                                <span>{orCondition.value.productIDs.length} collections</span>
                                                                <span> | Edit </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        {'collection'+index+'-'+orIndex === productPickerShow ?
                                                        <MulProductPickerModal
                                                            shop={shop}
                                                            host={host}
                                                            token={token}
                                                            resourceType="Collection"
                                                            open={collectionPickerVisible}
                                                            initialSelectionIds={orCondition.value && orCondition.value.productIDs?orCondition.value.productIDs:[]}
                                                            showVariants={false}
                                                            onSelect={(selectPayload) => {
                                                                const collectionIDs = selectPayload.selection.map(item => item.id)
                                                                console.log("collectionIDs", collectionIDs)
                                                                setCartValue(true)
                                                                setProductIndex([index, orIndex])
                                                                getCollectionProducts({variables:  {ids: collectionIDs}})
                                                                
                                                                if (selectPayload.selection.length === 0) {
                                                                if (orCondition.value) {
                                                                    // Clear selection if one exists
                                                                    handleChange(index, orIndex, null, "collection")
                                                                }
                                                                return
                                                                }
                                                                
                                                                //handleChange(index, orIndex, selectPayload.selection.map(item => {return {'id':item.id}}), "collection")
                                                                
                                                            }}
                                                            onClose={() => {setCollectionPickerVisible(false);setProductPickerShow('product')}}
                                                            key={index+"-"+orIndex}
                                                            />
                                                            :
                                                            false
                                                        }
                                                    </div>
                                                    </div> 
                                </ProductPicker>
                                </Stack.Item>
                                <div style={{margin:'0 -8px'}}>
                                <label>{`>=`}</label>
                                </div>
                                <Stack.Item fill>
                                    <TextField
                                        value={orCondition.value && orCondition.value.cartValue !== null ? orCondition.value.cartValue : ""}
                                        onChange={(value) => handleChange(index, orIndex, value, "cartValue")}
                                        autoComplete="off"
                                        placeholder="0.00"
                                        prefix={currencySymbol}
                                        type="number"
                                        error={orCondition.value === null ? true:false}
                                    />
                               </Stack.Item>
                            </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex, andCondition.any.length)}>
                                    <Icon
                                        source={DeleteMajor}
                                        color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        :
                        "discountCode" !== orCondition.fact ?
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                            <Stack wrap={false} alignment="center">
                                <Stack.Item>
                                <Select
                                options={factsOptions}
                                onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                value={orCondition.fact}
                                />
                                </Stack.Item>
                                <Stack.Item>
                                <label>is</label>
                                </Stack.Item>
                                <Stack.Item>
                                <Select
                                options={operatorsOptions}
                                onChange={(value) => handleOperatorChange(index, orIndex, value)}
                                value={orCondition.operator}
                                />
                                </Stack.Item>
                                <Stack.Item fill>
                                    <TextField
                                        value={orCondition.value}
                                        onChange={(value) => handleChange(index, orIndex, value, "cart")}
                                        autoComplete="off"
                                        placeholder="0.00"
                                        prefix={currencySymbol}
                                        type="number"
                                        error={orCondition.value === null ? true:false}
                                    />
                               </Stack.Item>
                            </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex, andCondition.any.length)}>
                                    <Icon
                                        source={DeleteMajor}
                                        color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        :
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                                <Stack wrap={false} alignment="center">
                                    <Stack.Item>
                                        <Select
                                          options={factsOptions}
                                          onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                          value={orCondition.fact}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                    <Select
                                        options={discountOperatorOptions}
                                        onChange={(value) => handleOperatorChange(index, orIndex, value)}
                                        value={orCondition.operator}
                                        />
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <div style={{position:"relative"}}>
                                        <TextField
                                          label='Discount code (optional)'
                                          labelHidden
                                          placeholder='e.g. “DISCOUNT CODE”'
                                          value={Array.isArray(orCondition.value)?orCondition.value.join(','):orCondition.value}
                                          onChange={(value) => handleChange(index, orIndex, value, "discountCode")}
                                          autoComplete='off'
                                        />
                                        <Modal
                                            activator={
                                            <DiscountEditWrapper onClick={() => handleDiscountEditModalChange()}>
                                                <Icon source={SettingsMajor} />
                                            </DiscountEditWrapper>}
                                            open={discountEditModalVisible}
                                            onClose={handleDiscountEditModalChange}
                                            title="Add Discount Codes"
                                            large
                                            >
                                                <Modal.Section>
                                                    <Stack spacing="extraLoose">
                                                    <Stack.Item fill>
                                                        <TextField
                                                            label="discount codes"
                                                            labelHidden
                                                            value={coupons}
                                                            onChange={(value) => setCoupons(value)}
                                                            multiline={6}
                                                            autoComplete="off"
                                                            placeholder="Enter a discount code"
                                                        />
                                                        {
                                                            couponDuplicated.length > 0 &&
                                                            <div style={{paddingTop:'5px'}}>
                                                                <InlineError message={`The following codes are already in the list: ${couponDuplicated.join(', ')}`} fieldID="duplicated-coupon" />
                                                            </div>
                                                        }
                                                        <div style={{paddingTop:'20px', textAlign: 'right'}}>
                                                            <Button primary onClick={() => handleAddCodes(orCondition.value, index, orIndex)} disabled={coupons ? false : true}>Add code</Button>
                                                        </div>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <div style={{borderBottom:'1px solid #C9CCCF', marginBottom:'10px'}}>
                                                            <Heading>Discount Codes ({orCondition?.value ? (Array.isArray(orCondition.value) ? orCondition.value.length:orCondition.value.includes('\n') && !orCondition.value.includes(',') ? orCondition.value.split('\n').length : orCondition.value.split(',').length) : 0})</Heading>
                                                            <span>{formatLastSavedDate(existingOffer?.triggers?.conditions?.all[index]?.any[orIndex]?.updatedAt, "Last updated")}</span>
                                                        </div>
                                                        {orCondition?.value ?
                                                            (Array.isArray(orCondition.value) ?
                                                            <div style={{maxHeight:'450px', overflow:'auto'}}>
                                                                {
                                                                orCondition.value.toReversed().map((code, i) => {
                                                                    return <DiscountCodeWrapper key={i}>{code.trim()}</DiscountCodeWrapper>
                                                                })
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{maxHeight:'450px', overflow:'auto'}}>
                                                                { orCondition?.value.includes('\n') && !orCondition.value.includes(',') ?
                                                                orCondition.value.split('\n').toReversed().map((code, i) => {
                                                                    return <DiscountCodeWrapper key={i}>{code.trim()}</DiscountCodeWrapper>
                                                                })
                                                                :
                                                                orCondition.value.split(',').toReversed().map((code, i) => {
                                                                    return <DiscountCodeWrapper key={i}>{code.trim()}</DiscountCodeWrapper>    
                                                                })
                                                                }
                                                            </div>)
                                                            :
                                                            false
                                                        }
                                                    </Stack.Item>
                                                    </Stack>
                                                </Modal.Section>
                                                
                                        </Modal>
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex, andCondition.any.length)}>
                                    <Icon
                                      source={DeleteMajor}
                                      color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        :
                        <Stack wrap={false} alignment="center">
                            <Stack.Item fill>
                                <Stack wrap={false}>
                                    <Stack.Item>
                                        <Select
                                        options={factsOptions}
                                        onChange={(value) => handleSelectedFactChange(index, orIndex, value)}
                                        value={orCondition.fact}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Select
                                        options={productOperatorOptions}
                                        onChange={(value) => handleOperatorChange(index, orIndex, value)}
                                        value={orCondition.operator}
                                        />
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <ProductPicker>
                                            { ("inCollection" === orCondition.operator || "notInCollection" === orCondition.operator) ?
                                                <div className={!orCondition.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                                                    <div>
                                                        <div 
                                                            className='mockInput'
                                                            onClick={() => {setProductPickerShow('collection'+index+'-'+orIndex);setCollectionPickerVisible(true)}}
                                                            >
                                                            { 
                                                            !orCondition.value ?
                                                                <span>Select collections</span> :
                                                                <div>
                                                                <span>{orCondition.value.length} collections</span>
                                                                <span> | Edit </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        {'collection'+index+'-'+orIndex === productPickerShow ?
                                                        <MulProductPickerModal
                                                            shop={shop}
                                                            host={host}
                                                            token={token}
                                                            resourceType="Collection"
                                                            open={collectionPickerVisible}
                                                            initialSelectionIds={orCondition.value?orCondition.value:[]}
                                                            showVariants={false}
                                                            onSelect={(selectPayload) => {
                                                                const collectionIDs = selectPayload.selection.map(item => item.id)
                                                                setProductIndex([index, orIndex])
                                                                getCollectionProducts({variables:  {ids: collectionIDs}})
                                                                
                                                                if (selectPayload.selection.length === 0) {
                                                                if (orCondition.value) {
                                                                    // Clear selection if one exists
                                                                    handleChange(index, orIndex, null, "collection")
                                                                }
                                                                return
                                                                }
                                                                
                                                                //handleChange(index, orIndex, selectPayload.selection.map(item => {return {'id':item.id}}), "collection")
                                                                
                                                            }}
                                                            onClose={() => {setCollectionPickerVisible(false);setProductPickerShow('product')}}
                                                            key={index+"-"+orIndex}
                                                            />
                                                            :
                                                            false
                                                        }
                                                    </div>
                                                    </div>
                                                    :
                                                    "hasTag" === orCondition.operator || "hasNoTag" === orCondition.operator?
                                                        <Modal
                                                        activator={(<div className='mockInputContainer'>                                                        
                                                        <div className='mockInput'
                                                          onClick={handleTagModalChange}
                                                         >{!orCondition.value?
                                                          <span>Select Tag</span>
                                                          :
                                                          <span>{orCondition.value.tag} | Edit</span>
                                                          }
                                                           </div>
                                                       </div>)}
                                                        open={tagModalVisible}
                                                        onClose={handleTagModalChange}
                                                        title="Select a product tag"
                                                        primaryAction={{
                                                        content: 'Save',
                                                        onAction: () => saveTagProducts([index, orIndex]),
                                                        }}
                                                        secondaryActions={[
                                                        {
                                                            content: 'Cancel',
                                                            onAction: handleTagModalChange,
                                                        },
                                                        ]}
                                                        >
                                                            <Modal.Section>
                                                            <TextContainer>
                                                                {((tags.loading && !tags.data) || tagProducts.loading)?
                                                                <Spinner accessibilityLabel="Loading Tags" size="large" />
                                                                :
                                                                tags.data ?
                                                                <div>
                                                                  <TextField
                                                                    label="Search Tag"
                                                                    labelHidden
                                                                    prefix={<Icon
                                                                        source={SearchMajor}
                                                                        color="base" />}
                                                                    autoComplete="off"
                                                                    value={searchTag}
                                                                    onChange={handleSearchTag}
                                                                    />
                                                                    <OptionList
                                                                        title="Available Tags"
                                                                        onChange={(val) => handleTagValueChange(val)}
                                                                        options={
                                                                            searchedTags.map(tag => 
                                                                        {return {value: tag.node, label: tag.node}}
                                                                        )
                                                                        }
                                                                        selected={selectedTag}
                                                                    />
                                                                </div>
                                                                :
                                                                false
                                                                }
                                                                <TextField 
                                                                    label="Fill in tags manually" 
                                                                    placeholder="Fill in tags here if you can’t find it in the list" 
                                                                    labelHidden
                                                                    onChange={(val) => setFilledTag(val) }
                                                                    value={filledTag}
                                                                />
                                                            </TextContainer>
                                                            </Modal.Section>
                                                        </Modal>
                                                    :
                                                    "hasVariants" === orCondition.operator ||
                                                    "hasNoVariants" === orCondition.operator ? (
                                                    <div
                                                      className={
                                                        !orCondition.value
                                                          ? "mockInputContainer mockInputContainer-error"
                                                          : "mockInputContainer"
                                                      }
                                                    >
                                                      <div>
                                                        <div
                                                          className="mockInput"
                                                          onClick={() => {
                                                            setProductPickerShow(
                                                              "variants" + index + "-" + orIndex
                                                            )
                                                            setVariantsPickerVisible(true)
                                                          }}
                                                        >
                                                          {!orCondition.value ? (
                                                            <span>Select variants</span>
                                                          ) : (
                                                            <div>
                                                              <span>
                                                                {orCondition.value.length}{" "}
                                                                variants
                                                              </span>
                                                              <span> | Edit </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                        {"variants" + index + "-" + orIndex ===
                                                        productPickerShow ? (
                                                          <ProductPickerModal
                                                            host={host}
                                                            shop={shop}
                                                            token={token}
                                                            resourceType="Variant"
                                                            open={variantsPickerVisible}
                                                            onSelect={(selectPayload) => {
                                                              console.log(
                                                                "selectPayload",
                                                                selectPayload
                                                              )
                                                              if (
                                                                selectPayload.selection.length ===
                                                                0
                                                              ) {
                                                                handleChange(
                                                                  index,
                                                                  orIndex,
                                                                  null,
                                                                  "variants"
                                                                )
                                                              } else {
                                                                const value = selectPayload.selection.map(
                                                                  (item) => {
                                                                    return {
                                                                      id: item.id,
                                                                      variants: item.variants.map(
                                                                        (variant) => {
                                                                          return {
                                                                            id: variant.id,
                                                                          }
                                                                        }
                                                                      ),
                                                                    }
                                                                  }
                                                                )
                                                                handleChange(
                                                                  index,
                                                                  orIndex,
                                                                  value,
                                                                  "variants"
                                                                )
                                                              }
                                                            }}
                                                            onClose={() => {
                                                              setVariantsPickerVisible(false)
                                                              setProductPickerShow("product")
                                                            }}
                                                            key={index + "-" + orIndex}
                                                            initialProduct={orCondition.product
                                                              ? orCondition.product
                                                              : []}
                                                          />
                                                        ) : (
                                                          false
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                    :
                                                    <div className={!orCondition.product ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                                                    <div>
                                                        <div 
                                                            className='mockInput'
                                                            onClick={() => {setProductPickerShow('mulProducts-'+index+'-'+orIndex);setMulProductPickerVisible(true)}}
                                                            >
                                                            { 
                                                            !orCondition.product ?
                                                                <span>Select products</span> :
                                                                <div>
                                                                <span>{orCondition.product.length} products</span>
                                                                <span> | Edit </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        {'mulProducts-'+index+'-'+orIndex === productPickerShow ?
                                                        <MulProductPickerModal
                                                            shop={shop}
                                                            host={host}
                                                            token={token}
                                                            resourceType="Product"
                                                            open={mulProductPickerVisible}
                                                            initialSelectionIds={orCondition.product?orCondition.product:[]}
                                                            showVariants={false}
                                                            onSelect={(selectPayload) => {
                                                                setProductIndex([index, orIndex])
                                                                if (selectPayload.selection.length === 0) {
                                                                if (orCondition.value) {
                                                                    // Clear selection if one exists
                                                                    //handleChange(index, orIndex, null, "product")
                                                                    setProductIDs(null)
                                                                }
                                                                return
                                                                }
                                                                setProductIDs(selectPayload.selection.map(item => {return {'id':item.id}}))
                                                                //handleChange(index, orIndex, selectPayload.selection.map(item => {return {'id':item.id}}), "product")
                                                            }}
                                                            onClose={() => {setMulProductPickerVisible(false);setProductPickerShow('product')}}
                                                            key={index+"-"+orIndex}
                                                            />
                                                            :
                                                            false
                                                        }
                                                    </div>
                                                    </div>
                                                } 
                                        </ProductPicker>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DeleteWrapper onClick={()=>deleteOrCondition(index, orIndex)}>
                                <Icon
                                    source={DeleteMajor}
                                    color="critical" />
                                </DeleteWrapper>
                            </Stack.Item>
                        </Stack>
                        }
                        {orIndex < andCondition.any.length-1 ?
                            <div style={{margin:'20px 0'}}>
                                    <Tag>
                                        OR
                                    </Tag>
                            </div>
                            :
                            <div style={{marginTop:'20px'}}>
                                <Stack alignment="center" distribution="equalSpacing">
                                    <Button outline={true} onClick={()=>addOrCondition(index)}>
                                        + OR
                                    </Button>
                                    { andCondition.any.length > 1 ?
                                        <DeleteWrapper onClick={()=>deleteAndCondition(index)}>
                                            <Icon
                                                source={DeleteMajor}
                                                color="critical"/>
                                        </DeleteWrapper>
                                        :
                                        false
                                    }
                                </Stack>
                            </div>
                        }
                    </div>
                    })
                    }
                </Card>
                {
                    index < andConditionsArray.length-1 ?
                    <div style={{margin:'20px 0'}}>
                            <Tag>
                                AND
                            </Tag>
                    </div>
                    :
                    <div style={{marginTop:'20px'}}>
                            <Button primary onClick={() => addAndCondition()}>
                                + AND
                            </Button>
                    </div>
                }
            </div>
        })
        }
        {
            "checkout" === type &&
              <>
                  <div style={{padding: '20px 0 0'}} className='third-step'>
                      <Checkbox
                        label="Swap product in cart with upsell product if added"
                        checked={swap}
                        onChange={handleSwapChange}
                      />
                      {
                        swap && 
                            <ChoiceList
                                title="Swap quantity selection"
                                titleHidden
                                choices={[
                                {
                                    label: 'Same Quantity',
                                    value: 'same',
                                },
                                {
                                    label: 'Fixed Quantity',
                                    value: 'fixed',
                                },
                                ]}
                                selected={swapQuantityOption}
                                onChange={(value) => setSwapQuantityOption(value)}
                            />
                     }
                     {
                    swap && "fixed" === swapQuantityOption[0] &&
                    <div style={{padding:'10px 0', maxWidth:'130px'}} className='swap-quantity'>
                        <TextField
                            value={swapQuantity}
                            onChange={(value) => handleSwapQuantityChange( value )}
                            autoComplete="off"
                            placeholder="0"
                            type="number"
                        />
                     </div>

                  }
                  </div>
                  
                  
                  {
                      offerType !== 'autopilot' &&
                          <div style={{padding: '20px 0 0'}} className='add-to-cart-auto-step'>
                              <Checkbox
                                label="Automatically add to cart when conditions are met"
                                checked={autoAdd}
                                onChange={handleAutoAddChange}
                              />
                          </div>
                  }
              </>
        }
        {"customization" !== type &&
        <div style={{padding: '20px 0 0'}}>
            <Checkbox
              label="Exclude products already in cart"
              checked={isExcludeProduct}
              onChange={handleExcludeProductChange}
            />
        </div>
        }
        {
            'extension' === checkoutType && 
            <div style={{padding: '20px 0 0'}}>
            <Checkbox
              label="Don't show if an upsell was already accepted"
              checked={isExcludeOffer}
              onChange={handleExcludeOfferChange}
            />
            </div>
        }
        </div>
    )
}
export default Conditions;